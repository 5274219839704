(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("antd"), require("_"), require("ReactRouterDOM"), require("mobxReact"));
	else if(typeof define === 'function' && define.amd)
		define("appManagementDetail", ["React", "antd", "_", "ReactRouterDOM", "mobxReact"], factory);
	else if(typeof exports === 'object')
		exports["appManagementDetail"] = factory(require("React"), require("antd"), require("_"), require("ReactRouterDOM"), require("mobxReact"));
	else
		root["appManagementDetail"] = factory(root["React"], root["antd"], root["_"], root["ReactRouterDOM"], root["mobxReact"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__11__) {
return 