import React, { Component } from 'react';
import { Form, Checkbox, message } from 'antd';
import DropTarget from './wrapper';
import * as _ from 'lodash';


const FormItem = Form.Item;
const computTreeList = Utils.computTreeList;
const IconFont = Loader.loadComponent('IconFont');
const DragDropContextProvider = Loader.loadComponent('ReactDnD', null, 'DragDropContextProvider');


@Form.create()
class ModuleForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      checkAlls: false,
      indeterminate: false,
      menuTreeList: [],
    };
    this.initId = props.initId;
    this.menuList = props.menuList;
    this.privilegeInfo = {
      id: '',
      menuList: '',
      sort:''
    }
  }

  moduleChange = (options) => {
    this.privilegeInfo = { ...this.privilegeInfo, ...options }
  }

  componentWillMount() {
    const { checkitem, checks, saveRef } = this.props;
    saveRef && saveRef(this)
    let menuTreeList = computTreeList(this.menuList, 'code', 'parentCode', true);

    //所有菜单树结构
    this.setCheckedMenu(checkitem, this.menuList);
    // 初始化privilegeIds
    this.moduleChange({
      menuList: _.uniqBy(checks, 'menuCode')
    });

    let c = checkitem.length;
    let m = this.menuList.filter(x => !x.module).map(v => v.id).length;
    this.setState({
      menuTreeList,
      checkAlls: c === m || c > m,
      indeterminate: c < m && c !== 0,
    })
  }

  /**
   * 全选
   */
  checkAll = e => {
    const check = e.target.checked;
    this.menuList.map(v => (v.checked = check));
    let menuList = this.menuList;
    this.setState({ checkAlls: check, indeterminate: false });
    this.moduleChange({
      menuList: menuList
        .filter(x => !x.module && x.checked === true)
        .map(v => {
          let item = {
            menuCode: v.code,
            sort: v.sort,
            operationCenterId: this.initId
          };
          return item;
        })
    });
    this.forceUpdate();
  };
  /**
   * 单项/反选/半选
   */
  checkSingle = (item) => {
    const checked = !item.checked;
    let menuList = this.menuList;
    let menu = menuList.find(v => v.code === item.code);
    this.checksingles(menu, checked);
    menuList = this.checkParents(menu, menuList);
    let newMenuList = [];
    menuList
      .filter(x => !x.module)
      .map(v => {
        if (v.code && v.checked === true) {
          newMenuList.push({
            menuCode: v.code,
            sort: v.sort,
            operationCenterId: this.initId
          });
        }
      });
    // 保存选中权限到后台
    this.moduleChange({ menuList: _.uniqBy(newMenuList, 'menuCode') });
    let arrs = menuList.map(v => v.checked === true);
    if (arrs.includes(false) && arrs.includes(true)) {
      this.setState({ checkAlls: false, indeterminate: true });
    } else {
      this.setState({ checkAlls: !arrs.includes(false), indeterminate: false });
    }
    // this.forceUpdate();
  }

  /**
   * checked递归
   */
  checksingles(v, checked) {
    let menuList = this.menuList;
    if (Array.isArray(v)) {
      v.map(v => {
        v.checked = checked;
        if (checked === true) {
          if (v.isSelect) {
            v.isSelect.map(m => {
              let item = menuList.filter(x => x.code === m);
              item.map(n => (n.checked = checked));
            });
          }
        } else {
          if (v.isCancel) {
            v.isCancel.map(m => {
              let item = menuList.filter(x => x.code === m);
              item.map(n => (n.checked = checked));
            });
          }
        }
        if (v.children) {
          this.checksingles(v.children, checked);
        }
      });
    } else {
      v.checked = checked;
      if (checked === true) {
        if (v.isSelect) {
          v.isSelect.map(m => {
            let item = menuList.filter(x => x.code === m);
            item.map(n => (n.checked = checked));
          });
        }
      } else {
        if (v.isCancel) {
          v.isCancel.map(m => {
            let item = menuList.filter(x => x.code === m);
            item.map(n => (n.checked = checked));
          });
        }
      }
      if (v.children) {
        this.checksingles(v.children, checked);
      }
    }
  }

  checkParents(menu, menuList) {
    let parentMenu = menuList.find(v => menu.parentCode === v.code);
    if (parentMenu) {
      let arr = parentMenu.children.map(v => v.checked);
      if (!arr.includes(true) && arr.includes(false)) {
        parentMenu.checked = false;
      } else {
        parentMenu.checked = arr.includes(true);
      }
      if (parentMenu.parentCode) {
        this.checkParents(parentMenu, menuList);
      }
    }
    return menuList;
  }

  /**
   * 渲染checkbox
   */
  renderCheckedModule(treeData) {
    return treeData.map((item, index) => {
      const children = (
        <span className={item.parentCode && item.children ? 'firSon' : 'secSon'}>
          {!item.module ? (
            <Checkbox onChange={() => this.checkSingle(item)} disabled={item.defaultChecked} checked={item.checked}>
              {item.text}
            </Checkbox>
          ) : (
            <DropTarget index={index} key={item.code} id={item.code} moveCard={this.moveCard} className="module">
              {item.module}
              <IconFont type="icon-S_Edit_Drug" style={{ float: 'right', fontSize: '16px' }} />
            </DropTarget>
          )}
          {Array.isArray(item.children) && item.children.length > 0 && this.renderCheckedModule(item.children)}
        </span>
      );
      return <span key={item.code}>{children}</span>;
    });
  }

  // 设置选中节点
  setCheckedMenu(checkitem, menuList) {
    // 获取用户的菜单树
    let myMenu = [];
    checkitem.map(v => {
      menuList.map(item => {
        if (v == item.code) {
          item.checked = true;
          myMenu.push(item);
          if (item.parentCode * 1) {
            let items = menuList.filter(x => x.code === item.parentCode);
            items[0].checked = true;
            myMenu.push(items[0]);
            if (items[0].parentCode * 1) {
              let itemss = menuList.filter(y => y.code === items[0].parentCode);
              itemss[0].checked = true;
              myMenu.push(itemss[0]);
            }
          }
        }
      });
    });
  }

  // 保存操作
  submitForm = (callback) => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return false;
      }
      const menuList = this.privilegeInfo.menuList;
      if (!menuList.length) {
        message.error('请配置至少一个模块！');
        return false;
      }
      const { menuTreeList } = this.state;
      const moduleList = menuTreeList.map((v, index) => (
        {
          operationCenterId: this.initId,
          moduleCode: v.code,
          sort: (index + 1) * 10
        }
      ));
      const moduleInfo = {
        menuList,
        moduleList
      };
      callback(moduleInfo)
    })
  };

  moveCard = (id, index) => {
    const { menuTreeList } = this.state;
    const sourceCard = menuTreeList.find(card => card.code === id);
    const sortCards = menuTreeList.filter(card => card.code !== id);
    sortCards.splice(index, 0, sourceCard);
    this.setState({ menuTreeList: sortCards });
  };

  render() {
    let { checkAlls, indeterminate, menuTreeList } = this.state;
    const { HTML5Backend } = this.props;
    return (
      <Form onSubmit={this.handleSave} autoComplete="off" className="roleForm">
        <div className="pid-info">
          <div className="check-all">
            <FormItem>
              <Checkbox indeterminate={indeterminate} checked={checkAlls} onClick={e => this.checkAll(e)} style={{ paddingLeft: '20px', marginTop: '20px' }}>
                全选
              </Checkbox>
            </FormItem>
            <DragDropContextProvider backend={HTML5Backend}>
              <div
                className="checkbox-group"
                style={{
                  height: 'calc(100% - 52px)'
                }}
              >
                {this.renderCheckedModule(menuTreeList)}
              </div>
            </DragDropContextProvider>
          </div>
        </div>
      </Form>
    )
  }
}

export default ModuleForm;