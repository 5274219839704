import React from "react";
import { Spin, message } from "antd";
import PlaceTreeSelect from "../components/PlaceTreeSelect";
import { withRouter } from "react-router-dom";
import _ from "lodash";
const SelectList = Loader.loadBusinessComponent(
  "PlaceComponents",
  "SelectList"
);
const ConfirmComponent = Loader.loadBaseComponent("ConfirmComponent");

@withRouter
class OperationCenterDevice extends React.Component {
  constructor(props) {
    super(props);
    this.ocId = Utils.queryFormat(props.location.search).id;
    this.state = {
      list: [],
      currentList: [],
      sourceData: null,
      checkedKeys: props.placeIds,
      loading: true,
      handleVisible: false,
      cancelIds: [],
      disableKey: [],
      search:false,
      clear:true
    };
  }
  keywords = "";
  timer = null;
  componentWillMount() {
    this.queryList();
  }
  componentWillUnmount() {
    this.keywords = null;
    this.timer = null;
  }
  /**搜索已分配的场所 */
  search = value => {
    this.setState({search: true,clear:false})
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.keywords = value.trim();
      this.queryList();
    }, 500);
  };

  /**查询已分配场所 */
  queryList = () => {
    let {search} = this.state
    let option = {
      operationCenterId: this.ocId
    };
    if (this.keywords) {
      option.keywords = this.keywords;
    }
    if(!search){
      this.keywords = ''
      option.keywords = undefined;
    }
    Service.operation.queryPlacesByOperationCenterId(option).then(res => {
      if (search) {
        this.setState({
          sourceData: res.data.length ? res.data : [],
          loading: false,
          search:false
        });
      } else {
        this.setState({
          currentList: res.data,
          sourceData:null,
          loading: false,
          clear:true
        });
      }
    });
  };

  /**
   * @desc 未分配设备选择
   */
  onCheck = checkedKeys => {
    return new Promise(resolve =>
      this.setState({ checkedKeys }, () => resolve())
    );
  };

  /**
   * @desc 确认分配/ 取消分配
   * @param {Boolean} isCancel true: 取消分配, false：确认分配
   */
  submit = (isCancel = false) => {
    const { checkedKeys, currentList, cancelIds } = this.state;
    let { cancelSelect, placeIds = [] } = this.props;
    if(!isCancel){
      placeIds = this.treeComponent.getCheckedKeys()
    }
    this.setState({
      loading: true
    });
    Service.operation
      .updateOperationCenterPlaces({
        operationCenterId: this.ocId,
        [!isCancel ? "addPlaceIds" : "deletePlaceIds"]: !isCancel
          ? _.difference(
              [...new Set(checkedKeys.concat(placeIds))],
              currentList.map(v => v.id)
            )
          : cancelIds
      })
      .then(() => {
        message.success("操作成功");
        this.queryList();
        if (!isCancel) {
          cancelSelect && cancelSelect();
        }
        this.setState({
          handleVisible: false,
          cancelIds: []
        });
        SocketEmitter.emit(SocketEmitter.eventName.addAppManagement);
      },() => {
        this.setState({
          loading: false,
          handleVisible: false
        });
        message.error("分配失败!");
      })
  };

  del = delId => {
    this.setState({
      handleVisible: true,
      delId
    });
  };

  onModalCancel = () => {
    this.setState({
      handleVisible: false
    });
  };

  /**取消分配选中id */
  cancelChecked = ids => {
    this.setState({
      cancelIds: ids
    });
  };
  initTree = (treeComponent) => {
    this.treeComponent = treeComponent
  }

  render() {
    const {
      checkedKeys,
      loading,
      currentList = [],
      handleVisible,
      cancelIds,
      sourceData,
      clear
    } = this.state;
    const disableKeys = currentList.map(v => v.id);
    let keys = [...new Set(checkedKeys)];
    keys = _.difference(keys, disableKeys);
    return (
      <div className="place-tb-container">
        <Spin spinning={loading}>
          <div className="device-tlt">
            <span className="fl">全部场所：</span>
            <span className="fr">已分配场所（{currentList.length}个）</span>
          </div>
          <div className="device-edit-container">
            <PlaceTreeSelect
              onCheck={this.onCheck}
              currentList={currentList}
              checkedKeys={keys}
              disableKeys={disableKeys}
              submit={() => this.submit()}
              init={this.initTree}
            />
            {/* <PlaceTreeSelect 
              onCheck={this.onCheck}
              currentList={[]}
              checkedKeys={keys}
              submit={() => this.submit()}
            /> */}
            <SelectList
              list={sourceData ? sourceData : currentList}
              del={this.del}
              value={!clear ? this.keywords : ''}
              onChange={this.search}
              cancelIds={cancelIds}
              submit={() => this.submit(true)}
              cancelChecked={this.cancelChecked}
            />
          </div>
        </Spin>
        <ConfirmComponent
          title="是否确认移除该场所?"
          visible={handleVisible}
          onCancel={this.onModalCancel}
          onOk={() => this.submit(true)}
          width={320}
          img="delete"
          children={<div />}
        />
      </div>
    );
  }
}

export default OperationCenterDevice;
