import React from 'react'
import './index.less'
import PlaceTree from '../List/place'
import DeviceView from './components/device'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'

const SelectMap = Loader.loadBusinessComponent('MapComponent', 'SelectMap')
/**过滤单兵 */
const getDeviceTypes = () => {
  let ids = Dict.map.deviceType.filter(v => v.value !== '-1' && v.value !== Dict.map.db.value)
  return _.flatten(ids.map(v => v.value.split(',')))
}

@withRouter
class MapView extends React.Component {
  state = {
    list: [],
    points: [],
    placeIds: [],
    placeKey: Math.random()
  }
  tool=null
  componentDidMount(){
    this.queryDeivces()
  }
  //初始化场所列表勾选
  initPlace =(list) => {
    let placeIds = list.map(v => v.placeId)
    this.setState({
      placeIds: [...new Set(placeIds)],
      placeKey: Math.random()
    })
  }
  /**框选 */
  onChange = (data, update = false) => {
    let placeIds = []
    if(data.list){
      data.list.map(v => placeIds.push(v.placeId))
      this.setState({
        list: data.list,
        placeIds: [...new Set(placeIds)]
      })
    }
    if(update){
      this.queryDeivces()
    }
  }

  /**清除选中 */
  cancelSelect = () => {
    this.setState({
      placeIds: []
    })
  }

  clearDraws = (tool) => {
    this.tool = tool
  }

  /**获取未分配设备列表 */
  queryDeivces = () => {
    Service.device
      .queryDevicesByOperationCenter({
        queryType: 2,
        distributionState: 2,
        deviceTypes: getDeviceTypes(),
        operationCenterId: Utils.queryFormat(this.props.location.search).id
      })
      .then(res => {
        this.tool.close(true);
        this.setState({
          points: res.data.list
        });
        this.clear = true;
      });
  }
  render(){
    const { list, placeIds, points, placeKey } = this.state
    const hasPlace = !!BaseStore.menu.getInfoByName("resourceManagement")
    return(
      <div className='operation-device-map'>
        <div className='map-container'>
          <SelectMap 
            onChange={this.onChange}
            selectList={list}
            points={points}
            clearDraws={this.clearDraws}
          />
        </div>
        <div className='rect-container'>
          <div className='h3'>分配设备:</div>
          <DeviceView 
            selectList={list}
            initPlace ={this.initPlace}
            onChange={this.onChange}
          />
          {hasPlace && <div className='h3'>分配场所:</div>}
          {hasPlace && <div className='container'>
            <PlaceTree 
              placeIds={placeIds}
              cancelSelect={this.cancelSelect}
              key={placeKey}
            />
          </div>}
        </div>
      </div>
    )
  }
}

export default MapView