import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, message } from 'antd';
import { withRouter } from 'react-router-dom';
import * as _ from 'lodash';
import ModuleForm from './ModuleForm';

import './index.less';

const SceneDict = [
  {
    value: "101000",
    label: "cityAndTown",
    text: "羚眸 (城镇场景)"
  },
  {
    value: "102000",
    label: "community",
    text: "羚眸 (社区场景)"
  },
  // {
  //   value: "103000",
  //   label: "lingZhi",
  //   text: "羚眸（羚知场景）"
  // }
];

const WrapperView = Loader.loadBusinessComponent('SystemWrapper');

@withRouter
@Decorator.businessProvider('tab')
@Decorator.withEntryLog()
@observer
class roleMgr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sceneType: 'cityAndTown',
      formKey: Math.random(),
      btnLoad: false,
    }
  }

  async componentWillMount() {
    let { location, data } = this.props;
    let initId = Utils.queryFormat(location.search).id;
    this.initId = initId;
    // 查询所有菜单
    let resInit = await Service.operation.queryOperationCenterInitMenu({});
    // 查询运营中心选中菜单
    let resUser = await Service.operation.queryOperationCenterMenuAndPrivileges(initId);
    this.resInitData = resInit.data;
    this.resUserData = resUser.data;
    this.resUserData.menus.map(v => v.code = v.menuCode);
    this.resUserData.modules.map(v => {
      v.code = v.moduleCode;
      delete v.moduleCode;
    });

    this.handleSceneCheck();
    
    this.sceneCode = data.sceneCode;
    if(this.sceneCode) {
      // 已分配场景
      this.setState({
        sceneType: SceneDict.find(v => v.value == this.sceneCode).label
      }, () => {
        this.handleFormData();
      })
    } else {
      this.handleFormData();
    }

    Loader.loadScript('HTML5Backend', 'default').then(
      HTML5Backend => this.setState({ HTML5Backend })
    );
  }

  // 处理场景的默认选中项
  handleSceneCheck = () => {
    this.resInitData.map(v => {
      const menuDefaultCheck = v.menus.filter(v => v.menuStatus == 1);
      menuDefaultCheck.map(menu => {
        menu.defaultChecked = true;
        let item = v.modules.find(m => m.code == menu.moduleCode);
        if(item) {
          item.defaultChecked = true;
          item.menuStatus == 1;
        }
      })
    })
  }

  // 处理单个场景的form需要的所有数据
  handleFormData = () => {
    // 场景下所有菜单模块
    const sceneData = this.handleSceneData();
    // 当前运营中心的选中项
    const sourceData = this.handleSourceData(sceneData);
    this.menuList = this.handleMenuList(sceneData)
    const { checkitem, checks } = this.handleDefaultCheckitem(sourceData, sceneData);
    this.checkitem = checkitem;
    this.checks = checks;
    this.setState({
      formKey: Math.random()
    });
  }

  // 处理单个场景的默认菜单
  handleSceneData = () => {
    const { sceneType } = this.state;
    const sceneCode = SceneDict.find(v => v.label == sceneType).value;
    const sceneData = this.resInitData.find(v => v.scene.code == sceneCode);
    const cloneSceneData = _.cloneDeep(sceneData);
    return cloneSceneData;
  }

  // 处理运营中心的选中菜单
  handleSourceData = (sceneData=null) => {
    if(!sceneData) {
      sceneData = this.handleSceneData();
    }
    let sourceData = _.intersectionBy(_.cloneDeep(this.resUserData.menus),_.cloneDeep(sceneData.menus), 'code')
    return sourceData;
  }

  // 处理需要渲染的菜单
  handleMenuList = (sceneData=null) => {
    if(!sceneData) {
      sceneData = this.handleSceneData()
    }
    const userModules = _.cloneDeep(this.resUserData.modules);
    const menuList = userModules.length ? sceneData.menus.concat(userModules) : sceneData.menus.concat(sceneData.modules);

    let list = [];
    for (let i = 0, l = menuList.length; i < l; i++) {
      let item = menuList[i];
      item.text = item.name;
      item.checked = false;
      if (!item.moduleCode) {
        // 模块
        let module = {};
        module.module = item.name;
        module.code = item.code;
        module.moduleSort = item.sort;
        list.push(module);
      }else if (item.moduleCode && !item.parentCode){
        // 一级菜单
        item.parentCode = item.moduleCode;
        list.push(item);
      }else {
        // 次级菜单
        list.push(item);
      }
    }
    list = _.sortBy(_.uniqBy(list, 'code'), 'moduleSort');
    return list;
  }

  // 处理渲染菜单的默认选中项
  handleDefaultCheckitem = (sourceData, sceneData) => {
    let checkData = _.cloneDeep(sourceData);
    
    // 合并场景默认选中项
    const checkedMenus = sceneData.menus.filter(v => v.defaultChecked);
    const checkedModules = sceneData.modules.filter(v => v.defaultChecked);
    checkData = checkData.concat(checkedMenus, checkedModules);
    checkData = _.uniqBy(checkData, 'code');
    
    let checkitem = [], checks = [];
    if(checkData) {
      checkitem = checkData.map(v => v.code);
      checks = checkData.map(v => ({
          menuCode: v.code,
          sort: v.sort,
          operationCenterId: this.initId
        })
      )
    }
    return { checkitem, checks }
  }

  // 保存操作
  handleSave = () => {
    this.formRef.submitForm((moduleInfo) => {
      if(moduleInfo) {
        this.setState({ btnLoad: true });
        const { sceneType } = this.state;
        moduleInfo.sceneCode = SceneDict.find(v => v.label == sceneType).value;
        Service.operation
          .changeOperationCenterModuleMenu(moduleInfo)
          .then(() => {
            message.success('模块配置成功');
            this.setState({ btnLoad: false });
            this.handleCancel();
          })
          .catch(() => {
            this.setState({ btnLoad: false });
          });
      }
    });
  };

  // 取消操作
  handleCancel = () => {
    BaseStore.tab.closeCurrentTab({});
  };

  // 切换场景
  switchScene = (sceneType) => {
    this.setState({
      sceneType,
    }, () => {
      this.handleFormData();
    })
  }

  render() {
    let { HTML5Backend, sceneType, formKey } = this.state;
    if (!HTML5Backend) {
      return null;
    }
    return (
      <WrapperView width={'100%'} name={''}>
        <div className="module-edit-contanier">
          <div className="module-edit">
            <div className="baseInfo">
              {!this.sceneCode && (
                <div className='module-scene-switch'>
                  { SceneDict.map(v => (
                      <Button 
                        key={v.value}
                        onClick={() => this.switchScene(v.label)}
                        type={sceneType === v.label ? 'primary':''}
                      >{v.text}</Button>
                  ))}
                </div>
              )}
              <ModuleForm
                key={formKey}
                initId={this.initId}
                HTML5Backend={HTML5Backend}
                checkitem={this.checkitem}
                checks={this.checks}
                menuList={this.menuList}
                saveRef={form => this.formRef = form}
              />
            </div>
          </div>
        </div>
        <div className="setting-edit-btns role-btn-tl">
          <Button 
            className="cancel-btn ant-btn" 
            onClick={this.handleCancel} 
            style={{ display: 'inline-block' }}
          >
            取消
          </Button>
          <Button 
            className="save-btn ant-btn" 
            type="primary" 
            loading={this.state.btnLoad} 
            onClick={this.handleSave} 
            style={{ display: 'inline-block' }}
          >
            保存
          </Button>
        </div>
      </WrapperView>
    );
  }
}

export default roleMgr;
